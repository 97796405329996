import { Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import GlobalContext from '../../context-api/GlobalContext';

const {Title} = Typography;

export default () => {
    const context: any = useContext(GlobalContext);
    const [width, setWidth] = useState(context.windowWidth);
    const [categories, setCategories] = useState();

    useEffect(() => {
        if (width !== context.windowWidth) {
            setWidth(width);
        }
        if (!categories) {
            setCategories(context.categories);
        }
        return function cleanup () {}
    }, [context.categories]);

    if (categories === null) {
        return null;
    }

    return (
        <div className="content-wrapper omms-footer-category-links">
            <Title level={5}>Categories</Title>
            {categories
                && <CategoryGrid
                    categories={categories}
                />
            }
        </div>
    );
}

interface Props {
    categories: any;
}

const CategoryGrid = ({
    categories
}: Props) => {

    return (
        <div className="omms-category-list">
            {categories.length
                && categories.map((cat: any, i: number) => 
                    <div key={i}><Link to={`/category/${cat.id}`}>{cat.name}</Link></div>
                )
            }
        </div>
    );
}