import { CaretDownOutlined } from '@ant-design/icons'
import { Badge, Dropdown, Menu } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import GlobalContext from '../../context-api/GlobalContext'
import { AppContext } from '../AppContext'
import OmegaMedIco from '../Common/OmegaMedIco'

const TopRightMenu = () => {
  const { state } = React.useContext(AppContext)
  const context: any = useContext(GlobalContext)
  const history = useHistory()
  const [isLogin, setIsLogin] = useState(false)

  useEffect(() => {
    setIsLogin(context.isAuthenticated)
  }, [context])

  const menu = () => {
    return (
      <div className="omms-user-menu-ui">
        <Menu>
          <Menu.Item
            key="ORDERS"
            icon={
              <OmegaMedIco
                asset="order"
                height="18"
                width="18"
                fillColor="#E1E3ED"
              />
            }
            onClick={() => history.push('/my-orders')}
          >
            My Orders
          </Menu.Item>
          <Menu.Item
            key="PROFILE"
            icon={
              <OmegaMedIco
                asset="user"
                height="18"
                width="18"
                fillColor="#E1E3ED"
              />
            }
            onClick={() => history.push('/profile')}
          >
            Profile
          </Menu.Item>
          <Menu.Item
            key="LOGOUT"
            icon={
              <OmegaMedIco
                asset="logout"
                height="16"
                width="16"
                colorStroke="#E1E3ED"
              />
            }
            onClick={() => {
              context.SignOut()
              history.push('/')
            }}
          >
            Logout
          </Menu.Item>
        </Menu>
      </div>
    )
  }

  return (
    <div className="top-nav-wrap">
      {/* <span>
                Follow us on <span className="fb-text">Facebook</span> 
                <a href="#" target="_blank">
                    <OmegaMedIco asset="facebook-block" className="m-l-10 va-middle" height="23" width="23" />
                </a>
            </span> */}
      <ul className="top-nav">
        <li>
          <Link to="/">
            <OmegaMedIco
              asset="home"
              className="va-middle"
              height="23"
              width="23"
            />
          </Link>
        </li>
        <li>
          <Link to="/products">
            <span>All Products</span>
          </Link>
        </li>
        <li>
          <Link to="/about-us">
            <span>About Us</span>
          </Link>
        </li>
        <li>
          <Link to="/stores">
            <span>Store</span>
          </Link>
        </li>
        <li>
          <Link to="/faqs">
            <span>Help</span>
          </Link>
        </li>
        {isLogin && context.user && (
          <li className="p-relative p-r-0">
            {state.profile && state.profile.isStoreRep && (
              <Badge.Ribbon text="STORE REP" placement="start"></Badge.Ribbon>
            )}
            <span className="omms-user-greet">
              Hi {context.user?.first_name}
            </span>
          </li>
        )}
      </ul>
      {isLogin ? (
        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
          <div className="omms-user-menu-btn-ui">
            <div className="omms-user-avatar-ui">
              <OmegaMedIco
                asset="user"
                fillColor="#B8BDD1"
                className="va-middle"
                height="20"
                width="20"
              />
            </div>
            <CaretDownOutlined className="m-l-10" />
          </div>
        </Dropdown>
      ) : (
        <React.Fragment>
          <Link
            to="/login"
            className="ant-btn ant-btn-primary ant-btn-sm m-r-10"
          >
            <span>Login</span>
          </Link>
          <Link
            to="/register"
            className="ant-btn ant-btn-primary ant-btn-sm btn-red"
          >
            <span>SIGN UP</span>
          </Link>
        </React.Fragment>
      )}
    </div>
  )
}

export default TopRightMenu
