import React, { SVGProps } from 'react'

interface OmegaMedIcoProps {
  asset: string
  colorStroke?: string
  fillColor?: string
  height?: string
  width?: string
  className?: string
  strokeWidth?: string
  onClick?: () => void
  showPointer?: boolean
}

export default ({
  asset,
  colorStroke,
  height,
  width,
  className,
  fillColor,
  strokeWidth,
  showPointer,
  onClick,
}: OmegaMedIcoProps) => {
  let iconSvg: any = null
  const icoAttr: SVGProps<SVGPathElement> = {
    stroke: colorStroke || '#1F3179',
    strokeWidth: strokeWidth || '8px',
    strokeLinejoin: 'round',
    strokeLinecap: 'round',
    fill: fillColor || '#1F3179',
  }

  switch (asset) {
    case 'home':
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '115.736'}
          height={height || '90'}
          viewBox="0 0 115.736 90"
        >
          <path
            fill={icoAttr.fill}
            d="M56.333,25.6,19.29,56.111V89.039A3.215,3.215,0,0,0,22.5,92.254L45.019,92.2a3.215,3.215,0,0,0,3.2-3.215V69.751a3.215,3.215,0,0,1,3.215-3.215H64.291a3.215,3.215,0,0,1,3.215,3.215V88.967a3.215,3.215,0,0,0,3.215,3.225l22.507.062a3.215,3.215,0,0,0,3.215-3.215V56.089L59.407,25.6A2.449,2.449,0,0,0,56.333,25.6Zm58.513,20.736-16.8-13.845V4.664a2.411,2.411,0,0,0-2.411-2.411H84.386a2.411,2.411,0,0,0-2.411,2.411V19.253L63.987,4.454a9.644,9.644,0,0,0-12.256,0L.874,46.338a2.411,2.411,0,0,0-.321,3.4l5.123,6.228a2.411,2.411,0,0,0,3.4.327L56.333,17.364a2.449,2.449,0,0,1,3.074,0L106.668,56.29a2.411,2.411,0,0,0,3.4-.321l5.123-6.228a2.411,2.411,0,0,0-.342-3.4Z"
            transform="translate(0.001 -2.254)"
          />
        </svg>
      )
      break
    case 'facebook-block':
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '75.306'}
          height={height || '75.306'}
          viewBox="0 0 75.306 75.306"
        >
          <path
            fill={icoAttr.fill}
            d="M67.238,1.625H8.069A8.069,8.069,0,0,0,0,9.694V68.863a8.069,8.069,0,0,0,8.069,8.069H31.139v-25.6H20.55V39.278h10.59V30.093c0-10.447,6.219-16.218,15.745-16.218a64.156,64.156,0,0,1,9.333.814V24.943H50.961c-5.179,0-6.794,3.214-6.794,6.51v7.825H55.728L53.879,51.329H44.167v25.6H67.238a8.069,8.069,0,0,0,8.069-8.069V9.694A8.069,8.069,0,0,0,67.238,1.625Z"
            transform="translate(0 -1.625)"
          />
        </svg>
      )
      break
    case 'search':
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '72.798'}
          height={height || '72.811'}
          viewBox="0 0 72.798 72.811"
        >
          <path
            fill={icoAttr.fill}
            d="M71.808,62.949,57.631,48.773a3.41,3.41,0,0,0-2.417-1H52.9A29.563,29.563,0,1,0,47.777,52.9v2.318a3.41,3.41,0,0,0,1,2.417L62.949,71.808a3.4,3.4,0,0,0,4.82,0l4.024-4.024A3.429,3.429,0,0,0,71.808,62.949ZM29.576,47.777a18.2,18.2,0,1,1,18.2-18.2A18.19,18.19,0,0,1,29.576,47.777Z"
          />
        </svg>
      )
      break
    case 'shopping-cart':
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '40.32'}
          height={height || '28.799'}
          viewBox="0 0 40.32 28.799"
        >
          <path
            fill={icoAttr.fill}
            d="M-2.07,6.336A1.612,1.612,0,0,1-1.6,5.148,1.607,1.607,0,0,1-.414,4.68h8.46a1.65,1.65,0,0,1,1.026.342,1.6,1.6,0,0,1,.594.918l.864,3.528H34.793l.253-.756A1.489,1.489,0,0,1,36.594,7.56a1.108,1.108,0,0,1,.468.072,1.55,1.55,0,0,1,.864.576,1.573,1.573,0,0,1,.324.972,2.012,2.012,0,0,1-.072.468L33.93,23.8a1.548,1.548,0,0,1-.576.864,1.582,1.582,0,0,1-.972.324H12.258a1.6,1.6,0,0,1-1.026-.359,1.681,1.681,0,0,1-.594-.9L6.75,7.956H-.414a1.628,1.628,0,0,1-1.17-.468A1.536,1.536,0,0,1-2.07,6.336Zm13.392,6.408,2.232,8.928h17.6l.071-.323,2.593-8.6h-22.5Zm1.512,17.424a3.159,3.159,0,0,1,.99-2.34,3.251,3.251,0,0,1,2.358-.972,3.311,3.311,0,0,1,0,6.623,3.25,3.25,0,0,1-2.358-.971A3.163,3.163,0,0,1,12.834,30.168Zm2.34-14.616H21.15v3.311H15.893Zm8.82,3.312.036-3.311h5.832L29,18.864Zm1.332,11.3a3.16,3.16,0,0,1,.991-2.34,3.248,3.248,0,0,1,2.358-.972,3.311,3.311,0,0,1,0,6.623,3.248,3.248,0,0,1-2.358-.971A3.16,3.16,0,0,1,25.326,30.168Z"
            transform="translate(2.07 -4.68)"
          />
        </svg>
      )
      break
    case 'pin-marker':
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '64.466'}
          height={height || '93.118'}
          viewBox="0 0 64.466 93.118"
        >
          <path
            fill={icoAttr.fill}
            d="M40.108,3.375c-17.8,0-32.233,13.408-32.233,29.928,0,23.28,32.233,63.19,32.233,63.19S72.341,56.582,72.341,33.3C72.341,16.783,57.9,3.375,40.108,3.375Zm0,42.731a10.5,10.5,0,1,1,10.5-10.5A10.5,10.5,0,0,1,40.108,46.106Z"
            transform="translate(-7.875 -3.375)"
          />
        </svg>
      )
      break
    case 'linkedin':
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '75.616'}
          height={height || '75.616'}
          viewBox="0 0 75.616 75.616"
        >
          <path
            fill={icoAttr.fill}
            d="M74.069,4.5H10.961C7.514,4.5,4.5,6.982,4.5,10.389V73.635a6.653,6.653,0,0,0,6.461,6.48H74.049a6.316,6.316,0,0,0,6.067-6.48V10.389A5.8,5.8,0,0,0,74.069,4.5ZM27.939,67.529H17.106V33.848H27.939ZM22.9,28.727h-.079a5.56,5.56,0,0,1-5.712-5.811,5.6,5.6,0,0,1,5.85-5.811,5.58,5.58,0,0,1,5.791,5.811A5.594,5.594,0,0,1,22.9,28.727Zm44.633,38.8H56.7V49.113c0-4.412-1.576-7.426-5.5-7.426a5.927,5.927,0,0,0-5.554,4,7.294,7.294,0,0,0-.374,2.659V67.529H34.439V33.848H45.272v4.688c1.576-2.245,4.038-5.476,9.77-5.476,7.111,0,12.488,4.688,12.488,14.792V67.529Z"
            transform="translate(-4.5 -4.5)"
          />
          \
        </svg>
      )
      break
    case 'instagram':
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '75.635'}
          height={height || '75.635'}
          viewBox="0 0 75.635 75.635"
        >
          <path
            fill={icoAttr.fill}
            d="M58.075,10.8A15.8,15.8,0,0,1,73.833,26.56V58.075A15.8,15.8,0,0,1,58.075,73.832H26.56A15.8,15.8,0,0,1,10.8,58.075V26.56A15.8,15.8,0,0,1,26.56,10.8H58.075m0-6.3H26.56A22.125,22.125,0,0,0,4.5,26.56V58.075a22.125,22.125,0,0,0,22.06,22.06H58.075a22.125,22.125,0,0,0,22.06-22.06V26.56A22.125,22.125,0,0,0,58.075,4.5Z"
            transform="translate(-4.5 -4.5)"
          />
          <path
            fill={icoAttr.fill}
            d="M28.352,18.454a4.727,4.727,0,1,1,4.727-4.727A4.716,4.716,0,0,1,28.352,18.454Z"
            transform="translate(29.95 3.606)"
          />
          <path
            fill={icoAttr.fill}
            d="M30.159,17.553A12.606,12.606,0,1,1,17.553,30.159,12.618,12.618,0,0,1,30.159,17.553m0-6.3A18.909,18.909,0,1,0,49.068,30.159,18.914,18.914,0,0,0,30.159,11.25Z"
            transform="translate(7.659 7.659)"
          />
        </svg>
      )
      break
    case 'twitter':
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '86.417'}
          height={height || '70.228'}
          viewBox="0 0 86.417 70.228"
        >
          <path
            fill={icoAttr.fill}
            d="M88.987,13.131A35.447,35.447,0,0,1,78.8,15.922a17.783,17.783,0,0,0,7.8-9.809,35.493,35.493,0,0,1-11.259,4.3A17.746,17.746,0,0,0,45.13,26.586,50.334,50.334,0,0,1,8.587,8.062a17.748,17.748,0,0,0,5.487,23.67,17.657,17.657,0,0,1-8.03-2.218c0,.074,0,.148,0,.223A17.741,17.741,0,0,0,20.264,47.122a17.759,17.759,0,0,1-8.007.3A17.747,17.747,0,0,0,28.819,59.738,35.571,35.571,0,0,1,6.8,67.328a35.956,35.956,0,0,1-4.229-.248,50.181,50.181,0,0,0,27.177,7.966c32.611,0,50.444-27.016,50.444-50.444q0-1.153-.051-2.294a36.016,36.016,0,0,0,8.847-9.177Z"
            transform="translate(-2.571 -4.817)"
          />
        </svg>
      )
      break
    case 'password-eye':
      icoAttr.fill = 'none'
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '108'}
          height={height || '80.727'}
          viewBox="0 0 108 80.727"
        >
          <g transform="translate(2.5 -2)">
            <path
              {...icoAttr}
              d="M1.5,42.364S19.682,6,51.5,6s50,36.364,50,36.364-18.182,36.364-50,36.364S1.5,42.364,1.5,42.364Z"
            />
            <path
              {...icoAttr}
              d="M40.773,27.136A13.636,13.636,0,1,1,27.136,13.5,13.636,13.636,0,0,1,40.773,27.136Z"
              transform="translate(24.364 15.227)"
            />
          </g>
        </svg>
      )
      break
    case 'password-eye-hide':
      icoAttr.fill = 'none'
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '108'}
          height={height || '116.355'}
          viewBox="0 0 108 116.355"
        >
          <g transform="translate(2.5 13.177)">
            <path
              {...icoAttr}
              d="M1.5,42.364S19.682,6,51.5,6s50,36.364,50,36.364-18.182,36.364-50,36.364S1.5,42.364,1.5,42.364Z"
            />
            <path
              {...icoAttr}
              d="M40.773,27.136A13.636,13.636,0,1,1,27.136,13.5,13.636,13.636,0,0,1,40.773,27.136Z"
              transform="translate(24.364 15.227)"
            />
            <line
              strokeWidth="8px"
              fill={icoAttr.fill}
              stroke={icoAttr.stroke}
              y1="111"
              x2="100"
              transform="translate(2 -10.5)"
            />
          </g>
        </svg>
      )
      break
    case 'user':
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '91.154'}
          height={height || '84.643'}
          viewBox="0 0 91.154 84.643"
        >
          <path
            fill={icoAttr.fill}
            d="M56.587,64.649v-5.37c7.172-4.042,13.022-14.118,13.022-24.2,0-16.182,0-29.3-19.533-29.3S30.543,18.9,30.543,35.083c0,10.078,5.85,20.154,13.022,24.2v5.37C21.478,66.454,4.5,77.306,4.5,90.427H95.652c0-13.12-16.979-23.973-39.066-25.778Z"
            transform="translate(-4.499 -5.784)"
          />
        </svg>
      )
      break

    case 'order':
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '85.756'}
          height={height || '73.505'}
          viewBox="0 0 85.756 73.505"
        >
          <path
            fill={icoAttr.fill}
            d="M63.825,51.879V42.691a1.474,1.474,0,0,0-1.531-1.531H51.574V30.44a1.474,1.474,0,0,0-1.531-1.531H40.855a1.474,1.474,0,0,0-1.531,1.531V41.159H28.6a1.474,1.474,0,0,0-1.531,1.531v9.188A1.474,1.474,0,0,0,28.6,53.41H39.323V64.13a1.474,1.474,0,0,0,1.531,1.531h9.188a1.474,1.474,0,0,0,1.531-1.531V53.41h10.72a1.474,1.474,0,0,0,1.531-1.531ZM33.2,16.658H57.7V10.532H33.2v6.125Zm-18.376,0V77.912H13.29a10.316,10.316,0,0,1-7.561-3.158,10.315,10.315,0,0,1-3.158-7.561V27.377a10.316,10.316,0,0,1,3.158-7.561,10.316,10.316,0,0,1,7.561-3.158Zm56.66,0V77.912H19.416V16.658h7.657V9a4.575,4.575,0,0,1,4.594-4.594H59.231A4.575,4.575,0,0,1,63.825,9v7.657h7.657ZM88.327,27.377V67.193A10.784,10.784,0,0,1,77.607,77.912H76.076V16.658h1.531a10.316,10.316,0,0,1,7.561,3.158,10.316,10.316,0,0,1,3.158,7.561Z"
            transform="translate(-2.571 -4.407)"
          />
        </svg>
      )
      break

    case 'envelope':
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '106.007'}
          height={height || '71.557'}
          viewBox="0 0 106.007 71.557"
        >
          <path
            fill={icoAttr.fill}
            d="M.072,69.287V10.406q0-.1.307-1.942L35.033,38.109.481,71.332a8.663,8.663,0,0,1-.409-2.044Zm4.6-64.912A4.405,4.405,0,0,1,6.41,4.068H99.741a5.788,5.788,0,0,1,1.84.307L66.825,34.122l-4.6,3.68-9.1,7.462-9.1-7.462-4.6-3.68Zm.1,70.944L39.633,41.891,53.127,52.829,66.62,41.891l34.859,33.427a4.908,4.908,0,0,1-1.738.307H6.41a4.63,4.63,0,0,1-1.636-.307ZM71.22,38.109,105.772,8.464a6.1,6.1,0,0,1,.307,1.942V69.287a7.835,7.835,0,0,1-.307,2.044Z"
            transform="translate(-0.072 -4.068)"
          />
        </svg>
      )
      break

    case 'logout':
      icoAttr.fill = 'none'
      icoAttr.strokeWidth = '8px'
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '79.273'}
          height={height || '79.273'}
          viewBox="0 0 79.273 79.273"
        >
          <g transform="translate(-0.5 -0.5)">
            <path
              {...icoAttr}
              d="M28.258,75.773H12.419A7.919,7.919,0,0,1,4.5,67.854V12.419A7.919,7.919,0,0,1,12.419,4.5H28.258"
              transform="translate(0 0)"
            />
            <path
              {...icoAttr}
              d="M24,50.1,43.8,30.3,24,10.5"
              transform="translate(31.975 9.839)"
            />
            <path
              {...icoAttr}
              d="M61.016,18H13.5"
              transform="translate(14.758 22.137)"
            />
          </g>
        </svg>
      )
      break

    case 'chevron-right':
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '18.634'}
          height={height || '30.704'}
          viewBox="0 0 18.634 30.704"
        >
          <path
            fill={icoAttr.fill}
            d="M2.427,16.807,16.092,3.142a1.688,1.688,0,0,1,2.386,0l1.594,1.594a1.688,1.688,0,0,1,0,2.384L9.245,18l10.83,10.881a1.687,1.687,0,0,1,0,2.384l-1.594,1.594a1.688,1.688,0,0,1-2.386,0L2.427,19.193A1.688,1.688,0,0,1,2.427,16.807Z"
            transform="translate(20.567 33.353) rotate(180)"
          />
        </svg>
      )
      break

    case 'flare':
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '18.674'}
          height={height || '24.898'}
          viewBox="0 0 18.674 24.898"
        >
          <path
            fill={icoAttr.fill}
            d="M10.5,1.16A1.169,1.169,0,0,0,8.357.526c-6.023,8.8,2.536,9.2,2.536,13.479a3.112,3.112,0,1,1-6.225-.046V9.8A1.167,1.167,0,0,0,2.654,9,9.724,9.724,0,0,0,0,15.561a9.337,9.337,0,0,0,18.674,0c0-8.281-8.17-9.385-8.17-14.4Z"
          />
        </svg>
      )
      break

    case 'phone':
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '39.779'}
          height={height || '39.777'}
          viewBox="0 0 39.779 39.777"
        >
          <path
            fill={icoAttr.fill}
            d="M38.255,31.725a29.2,29.2,0,0,0-6.1-4.082c-1.828-.878-2.5-.86-3.792.072-1.077.778-1.774,1.5-3.014,1.231s-3.684-2.118-6.055-4.48-4.218-4.815-4.48-6.055.462-1.937,1.231-3.014c.932-1.294.959-1.964.072-3.792a28.626,28.626,0,0,0-4.082-6.1C10.7,4.173,10.4,4.463,9.671,4.726A13.443,13.443,0,0,0,7.508,5.875a6.526,6.526,0,0,0-2.6,2.742C4.394,9.731,3.8,11.8,6.838,17.216a47.979,47.979,0,0,0,8.436,11.251h0l.009.009.009.009h0A48.167,48.167,0,0,0,26.542,36.92c5.413,3.041,7.485,2.444,8.6,1.928a6.414,6.414,0,0,0,2.742-2.6,13.443,13.443,0,0,0,1.149-2.163C39.3,33.354,39.594,33.056,38.255,31.725Z"
            transform="translate(1.708 -5.149) rotate(9)"
          />
        </svg>
      )
      break

    case 'logo':
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '104.76'}
          height={height || '82.15'}
          viewBox="0 0 104.76 82.15"
        >
          <path
            fill={icoAttr.fill}
            d="M192.324,50.335c-1.013-1.191-2.047-2.562-3.24-3.777a13.363,13.363,0,0,0-9.707-4.244,14.3,14.3,0,0,0-10.335,4.252,20.655,20.655,0,0,0-6.05,12.917c-1,8.854,1.564,16.686,6.977,23.683,2.4,3.1,5.384,5.617,8.135,8.366A15.012,15.012,0,0,1,181.735,96a8.577,8.577,0,0,1-7.285,11.9c-2.152.112-4.313.089-6.47.091q-11.183.015-22.367,0c-2.9,0-4.83-1.379-5.5-3.92a5.037,5.037,0,0,1,4.442-6.205c.586-.039,1.177-.021,1.765-.021h23.371l.082-.171c-.33-.336-.655-.677-.991-1.007-3.144-3.091-6.455-6.023-9.052-9.63a37.938,37.938,0,0,1,1.634-46.859,38.332,38.332,0,0,1,16.354-11.614,40.353,40.353,0,0,1,17.451-2.612,39.9,39.9,0,0,1,21.608,8.009c8.391,6.386,13.665,14.773,15.126,25.236,1.673,11.989-1.728,22.555-9.957,31.482-1.908,2.07-3.987,3.981-5.993,5.96-.343.338-.713.649-1.071.973l.109.233h.995q11.846,0,23.691,0a5.048,5.048,0,0,1,1.279,9.933,8.348,8.348,0,0,1-1.967.205q-13.722.019-27.444.007c-4.3,0-7.6-2.186-8.783-5.932-1.13-3.576-.163-6.635,2.515-9.231,2.743-2.659,5.546-5.269,8.119-8.086a32,32,0,0,0,8.254-17.48c.935-6.718-.036-13.076-4.185-18.649a14.656,14.656,0,0,0-11.831-6.293A13.019,13.019,0,0,0,196.11,46.1C194.721,47.427,193.536,48.966,192.324,50.335Z"
            transform="translate(-139.954 -25.842)"
          />
          <g transform="translate(123.282 1.695)"></g>
        </svg>
      )
      break

    case 'filter':
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="39.192"
          height="26.128"
          viewBox="0 0 39.192 26.128"
        >
          <path
            fill={icoAttr.fill}
            d="M19.741,35.128h8.709V30.774H19.741ZM4.5,9v4.355H43.692V9Zm6.532,15.241H37.16V19.887H11.032Z"
            transform="translate(-4.5 -9)"
          />
        </svg>
      )
      break

    case 'message':
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="37.497"
          height="37.497"
          viewBox="0 0 37.497 37.497"
        >
          <path
            fill={icoAttr.fill}
            d="M36.747,3h-30A3.745,3.745,0,0,0,3.019,6.75L3,40.5,10.5,33H36.747a3.761,3.761,0,0,0,3.75-3.75V6.75A3.761,3.761,0,0,0,36.747,3ZM16.124,19.874h-3.75v-3.75h3.75Zm7.5,0h-3.75v-3.75h3.75Zm7.5,0h-3.75v-3.75h3.75Z"
            transform="translate(-3 -3)"
          />
        </svg>
      )
      break

    case 'arrow-right-circle':
      icoAttr.fill = 'none'
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '86.801'}
          height={height || '86.801'}
          viewBox="0 0 86.801 86.801"
        >
          <g transform="translate(1 1)">
            <path
              {...icoAttr}
              d="M81.8,42.4A39.4,39.4,0,1,1,42.4,3,39.4,39.4,0,0,1,81.8,42.4Z"
            />
            <path
              {...icoAttr}
              d="M18,43.52,33.76,27.76,18,12"
              transform="translate(24.4 14.64)"
            />
            <path
              {...icoAttr}
              d="M12,18H43.52"
              transform="translate(14.64 24.4)"
            />
          </g>
        </svg>
      )
      break

    case 'circle-check':
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '95'}
          height={height || '95'}
          viewBox="0 0 95 95"
        >
          <g transform="translate(-1399 -523)">
            <g
              stroke={icoAttr.stroke}
              strokeWidth={icoAttr.strokeWidth}
              fill="none"
              transform="translate(1399 523)"
            >
              <circle stroke="none" cx="47.5" cy="47.5" r="47.5" />
              <circle fill="none" cx="47.5" cy="47.5" r="43.5" />
            </g>
            <path
              fill={icoAttr.stroke}
              d="M13.367,33.349.576,20.558a1.968,1.968,0,0,1,0-2.783l2.783-2.783a1.968,1.968,0,0,1,2.783,0l8.616,8.616L33.214,5.154a1.968,1.968,0,0,1,2.783,0l2.783,2.783a1.968,1.968,0,0,1,0,2.783L16.15,33.349A1.968,1.968,0,0,1,13.367,33.349Z"
              transform="translate(1426.963 552.966)"
            />
          </g>
        </svg>
      )
      break

    case 'stop-symbol':
      icoAttr.fill = 'none'
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '82'}
          height={height || '82'}
          viewBox="0 0 82 82"
        >
          <g transform="translate(1 1)">
            <path
              {...icoAttr}
              d="M24.682,3H55.318L77,24.682V55.318L55.318,77H24.682L3,55.318V24.682Z"
            />
            <path {...icoAttr} d="M18,12V26.8" transform="translate(22 13.2)" />
            <path {...icoAttr} d="M18,24h0" transform="translate(22 30.8)" />
          </g>
        </svg>
      )
      break

    case 'trashbin-outlined':
      icoAttr.fill = 'none'
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '77.511'}
          height={height || '85.234'}
          viewBox="0 0 77.511 85.234"
        >
          <g transform="translate(-0.5 1)">
            <path
              {...icoAttr}
              d="M4.5,9H74.011"
              transform="translate(0 9.447)"
            />
            <path
              {...icoAttr}
              d="M61.564,18.447V72.511a7.723,7.723,0,0,1-7.723,7.723H15.223A7.723,7.723,0,0,1,7.5,72.511V18.447m11.585,0V10.723A7.723,7.723,0,0,1,26.809,3H42.255a7.723,7.723,0,0,1,7.723,7.723v7.723"
              transform="translate(4.723 0)"
            />
            <path
              {...icoAttr}
              d="M15,16.5V39.67"
              transform="translate(16.532 21.255)"
            />
            <path
              {...icoAttr}
              d="M21,16.5V39.67"
              transform="translate(25.979 21.255)"
            />
          </g>
        </svg>
      )
      break

    case 'info-circle':
      icoAttr.fill = 'none'
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '108.891'}
          height={height || '108.891'}
          viewBox="0 0 108.891 108.891"
        >
          <g transform="translate(1 1)">
            <path
              {...icoAttr}
              d="M103.891,53.446A50.446,50.446,0,1,1,53.446,3,50.446,50.446,0,0,1,103.891,53.446Z"
            />
            <path
              {...icoAttr}
              d="M18,38.178V18"
              transform="translate(35.446 35.446)"
            />
            <path
              {...icoAttr}
              d="M18,12h0"
              transform="translate(35.446 21.267)"
            />
          </g>
        </svg>
      )
      break

    case 'circle-close':
      icoAttr.fill = '#b8bdd1'
      iconSvg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || '30.849'}
          height={height || '30.849'}
          viewBox="0 0 30.849 30.849"
        >
          <path
            fill={fillColor || icoAttr.fill}
            d="M17.995,1.928A15.424,15.424,0,1,0,33.419,17.352,15.424,15.424,0,0,0,17.995,1.928Zm0,27.956A12.532,12.532,0,1,1,30.527,17.352,12.532,12.532,0,0,1,17.995,29.885ZM22.815,9.64l-4.82,4.82-4.82-4.82-2.892,2.892,4.82,4.82-4.82,4.82,2.892,2.892,4.82-4.82,4.82,4.82,2.892-2.892-4.82-4.82,4.82-4.82Z"
            transform="translate(-2.571 -1.928)"
          />
        </svg>
      )
      break
  }

  return (
    <span
      className={`omegamed-asset-ico${
        className ? ' ' + className : ''
      } ${asset} ${showPointer ? ' pointer' : ''}`}
      onClick={onClick}
    >
      {iconSvg}
    </span>
  )
}
