import React, { Component } from 'react';
import _sortBy from 'lodash.sortby';

import axios from 'axios';
import { generateGUID, shuffleSingleDimensionArray } from '../utils';

export enum REDIRECT_URI {
    CLIENTS = 'manage_clients',
    BOOKINGS = 'manage_bookings',
    CONSULT_CALLS = 'manage_consult_calls'
}

const GlobalContext = React.createContext({});

export class GlobalProvider extends Component {
    state = {
        isAuthenticated: null,
        isAdminMenu: true,
        user: null as any,
        name: "",
        email: "",
        userType: "",
        containerClass: "",
        logo: '',
        noShadowHeader: false,
        pageId: undefined as any,
        pageTitle: '',
        pageClass: '',
        categories: null as any,
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
        sessionId: '',
        previousPath: ''
    }

    resizeTimeOut: any = null;

    componentDidMount = async () => {
        window.addEventListener('resize', this.handleWindowResize);
        await this.initializeAuthSession()
    }

    handleWindowResize = () => {
        if (this.resizeTimeOut) {
            this.resizeTimeOut = null;
            clearTimeout(this.resizeTimeOut);
        }

        this.resizeTimeOut = setTimeout(() => {
            this.setState({
                windowHeight: window.innerHeight,
                windowWidth: window.innerWidth
            });
            this.resizeTimeOut = null;
        }, 250)
    }

    setUserState = () => {
        const storage = localStorage.getItem(`OMEGAMED_APP_STATE_${this.state.user?.username}`);
        const VaxAppState: any = storage ? JSON.parse(storage) : null
        if (VaxAppState) {
            this.setState({ isAdminMenu: VaxAppState.isAdminMenu });
        }
    }

    setStorageState = () => {
        localStorage.setItem(`OMEGAMED_APP_STATE_${this.state.user.username}`, JSON.stringify({ isAdminMenu: this.state.isAdminMenu }))
    }

    initializeAuthSession = async (user?: any) => {
        const token = localStorage.getItem('OMMSAuthToken');
        let sessionId = localStorage.getItem('OMMSSessionID');
        if (token) {

            this.setState({ isAuthenticated: true }, async () => {
                await axios.get('/token/me')
                    .then(res => {
                        const {data} = res.data;
                        this.setState({ user: { ...data } })
                    })
                    .catch(res => {
                        const {auth} = res.data;
                        if (!auth) {
                            this.signOut();
                        }
                    })
            });
        } else {
            this.setState({ isAuthenticated: false });
        }

        if (!sessionId) {
            sessionId = generateGUID('SessionID-', 20);
            localStorage.setItem('OMMSSessionID', sessionId);
        }
        this.setState({ sessionId })
    }

    signOut = () => {
        localStorage.removeItem('OMMSAuthToken');
        localStorage.removeItem('OMMSSessionID');
        this.setState({ isAuthenticated: false, user: null })
    }

    render() {

        return (
            <GlobalContext.Provider
                value={{
                    ...this.state,
                    initializeAuthSession: this.initializeAuthSession.bind(this),
                    SignOut: this.signOut,
                    SignIn: (token: string) => {
                        localStorage.setItem('OMMSAuthToken', token);
                        this.setState({ isAuthenticated: true });
                        this.initializeAuthSession();
                    },
                    setPageClass: (pageClass: string) => {
                        this.setState({ pageClass });
                    },
                    setNoShadowHeader: (noShadowHeader: boolean) => {
                        this.setState({ noShadowHeader })
                    },
                    setPageId: (pageId: any) => {
                        this.setState({ pageId })
                    },
                    getCategories: () => {
                        axios.get('/category')
                            .then((res: any) => {
                                const {data, success} = res.data;
                                if (success) {
                                    this.setState({ categories: data.items.filter((cat: any) => ![3,4,5].includes(cat.id)) });
                                }
                            })
                    },
                    getRandomCategories: (limit = 6) => {
                        if (!this.state.categories) return [];

                        const categoryIds = this.state.categories.map((c: any) => c.id);
                        const shuffledIds = shuffleSingleDimensionArray(categoryIds).splice(0, limit);

                        return this.state.categories.filter((cat: any) => shuffledIds.includes(cat.id));
                    },
                    setPreviousPath: (previousPath: string) => {
                        this.setState({ previousPath });
                    }
                }}
            >
                {this.props.children}
            </GlobalContext.Provider>
        )
    }
}

export default GlobalContext;