import React from 'react'
import axios from 'axios'
import { message } from 'antd'
import { useLocation } from 'react-router-dom'
import { AppContext } from './AppContext'
import { Types } from './AppReducer'
import { ProductType } from './Types'

// Currently unused, will think of a use case for this custom hook
export const useCartAction = () => {
  const { state, dispatch } = React.useContext(AppContext)

  const addItem = async (
    variant_id: number,
    quantity: number,
    isBuyNow = false,
  ) => {
    const locateItemInCart = state.products
      ? state.products.find((item) => item.item_id === variant_id)
      : undefined
    if (locateItemInCart && !isBuyNow) {
      quantity = quantity + locateItemInCart.quantity
    }

    const res = await axios.post('/cart/user/add', {
      variant_id,
      quantity,
    })

    const { success, data } = res.data

    let storedIds = localStorage.getItem('OMMSUnselectedCartItems'),
      unselectedIds: number[] = []
    if (storedIds) {
      unselectedIds = JSON.parse(storedIds)
    }
    const items = data.items.map((p: ProductType) => ({
      ...p,
      isSelected: !unselectedIds.includes(p.item_id),
    }))

    if (success) {
      if (locateItemInCart) {
        dispatch({
          type: Types.UpdateCartItem,
          payload: {
            item_id: variant_id,
            quantity,
          },
        })
      } else {
        dispatch({
          type: Types.ReplaceCartItems,
          payload: items,
        })
      }

      const { total, sub_total, number_of_items, delivery_fee, discount } = data

      dispatch({
        type: Types.CartUpdate,
        payload: {
          total,
          sub_total,
          number_of_items,
          delivery_fee,
          discount,
        },
      })
    }

    return success
  }

  const buyNow = async (variant_id: number, quantity: number) => {
    const buyNow = { buy_now: variant_id, buy_now_qty: quantity }
    localStorage.setItem('OMMSBuyNowItem', JSON.stringify(buyNow))
    const checkoutInfo = await checkoutItems(buyNow)

    if (checkoutInfo) {
      dispatch({
        type: Types.SetCheckoutInfo,
        payload: {
          ...state.checkoutInfo,
          ...checkoutInfo,
          loaded: true,
        },
      })
      return checkoutInfo
    }
    return false
  }

  return {
    ...state.checkoutInfo,
    addItem,
    buyNow,
  }
}

export const useCheckoutAction = () => {
  const { state, dispatch } = React.useContext(AppContext)

  const loadCheckoutInfo = async () => {
    const lsBuyNow = localStorage.getItem('OMMSBuyNowItem')

    const checkout = lsBuyNow
      ? JSON.parse(lsBuyNow)
      : {
          items: state.products
            .filter((prod) => prod.isSelected)
            .map((prod) => prod.item_id),
        }

    const checkoutInfo = await checkoutItems(checkout)

    if (checkoutInfo) {
      const billing_addresses = state.profile.billing_addresses!
      const index = billing_addresses.findIndex((add) => add.is_default)
      let selectedBillAddress = null
      if (index >= 0) {
        const {
          line1,
          barangay,
          city,
          province,
          zipcode,
          id,
        } = billing_addresses[index]
        const address = [line1, barangay, city, province, zipcode].join(' ')
        selectedBillAddress = {
          address,
          id,
          index,
        }
      }

      dispatch({
        type: Types.SetCheckoutInfo,
        payload: {
          ...state.checkoutInfo,
          ...checkoutInfo,
          products: checkoutInfo.products.map((p: any) => ({
            ...p,
            key: p.item_id,
          })),
          selectedBillAddress,
          loaded: true,
        },
      })
      // Store ITEM in localstorage
      if (lsBuyNow) {
        localStorage.setItem(
          'OMMSBuyNowItemList',
          JSON.stringify(checkoutInfo.products),
        )
      }
    } else {
      message.error('Oops, Something went wrong...Please try again later')
    }
  }

  return {
    ...state.checkoutInfo,
    loadCheckoutInfo,
  }
}

export const fetchUserCartInfo = () => {
  return axios.get(`/cart/user/`).then((res) => {
    if (res.data.data) {
      const {
        items,
        number_of_items,
        discount,
        sub_total,
        total,
        delivery_fee,
      } = res.data.data as any

      let storedIds = localStorage.getItem('OMMSUnselectedCartItems'),
        unselectedIds: number[] = []
      if (storedIds) {
        unselectedIds = JSON.parse(storedIds)
      }

      return {
        products: items.map((p: any) => ({
          ...p,
          discount: parseFloat(p.discount),
          price: parseFloat(p.price),
          sale_price: parseFloat(p.sale_price),
          key: p.item_id,
          isSelected: !unselectedIds.includes(p.item_id),
        })) as ProductType[],
        info: {
          total,
          sub_total,
          number_of_items,
          delivery_fee,
          discount,
          loaded: true,
          unselectedCartItems: unselectedIds,
        },
      } as any
    }
  })
}

/*
 * buy_now param is the variant_id of the product
 */
export const checkoutItems = async (
  payload:
    | {
        buy_now: number
        buy_now_qty: number
      }
    | {
        items: number[]
      },
) => {
  const res = await axios.post('/cart/checkout/preview', {
    ...payload,
    delivery_fee: 0,
  })

  if (res.status === 200) {
    let {
      delivery_fee,
      discount,
      number_of_items,
      sub_total,
      total,
      items: products,
    } = res.data
    const items = products.map((item: any) => item.item_id)
    return {
      delivery_fee,
      discount,
      number_of_items,
      sub_total,
      total,
      items,
      products,
    }
  }
  return null
}

export const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}
