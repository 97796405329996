import React from "react";
import SearchControl from "./SearchControl";
import TopRightMenu from "./TopRightMenu";

import PlainLogo from "../../style/media/logo.svg";
import LogoWithText from "../../style/media/logo-and-text.svg";
import { useHistory } from "react-router-dom";

interface HeaderProps {
	noShadow?: boolean;
}

export default React.memo(({ noShadow }: HeaderProps) => {
	const history = useHistory();
	return (
		<header
			className={`main-header${noShadow ? " no-shadow" : ""}`}
			id="MainHeader"
		>
			<div className="content-wrapper">
				<a className="logo" onClick={() => history.push("/")}>
					<img src={LogoWithText} className="logo-with-text" />
					<img src={PlainLogo} className="plain-logo" />
				</a>
				<div className="site-nav">
					<TopRightMenu />
					<SearchControl />
				</div>
			</div>
		</header>
	);
});
