import passwordValidator from 'password-validator'
import moment from 'moment'
import { FormInstance } from 'antd/lib/form'

export const DATE_SAVE_FORMAT = 'YYYY-MM-DD'
export const DATE_VIEW_FORMAT = 'D MMMM YYYY'

export const getPasswordValidationSchema = (password: string): Promise<any> => {
  const PWSchema = new passwordValidator()
  PWSchema.has()
    .uppercase() // Must have uppercase letters
    .has()
    .lowercase() // Must have lowercase letters
    .has()
    .digits() // Must have digits
    .has()
    .symbols() // Must have symbols
    .has()
    .not()
    .spaces() // Should not have spaces

  const listOfInvalid: any = PWSchema.validate(password, { list: true })
  if (!listOfInvalid) {
    return Promise.resolve()
  }

  const _index = 0,
    errorType = listOfInvalid[_index]
  switch (errorType) {
    case 'uppercase':
      return Promise.reject(
        'Password should have at least 1 uppercase character.',
      )
    case 'lowercase':
      return Promise.reject(
        'Password should have at least 1 lowercase character.',
      )
    case 'digits':
      return Promise.reject('Password should have at least 1 number character.')
    case 'symbols':
      return Promise.reject('Password should have at least 1 symbol.')
    case 'spaces':
      return Promise.reject('Password should not contain space(s).')
    default:
      return Promise.resolve()
  }
}

export const getPasswordValidationSchemaObject = (
  password: string,
): Promise<any> => {
  const PWSchema = new passwordValidator()
  PWSchema.has()
    .uppercase() // Must have uppercase letters
    .has()
    .lowercase() // Must have lowercase letters
    .has()
    .digits() // Must have digits
    .has()
    .symbols() // Must have symbols
    .has()
    .not()
    .spaces() // Should not have spaces

  const listOfInvalid: any = PWSchema.validate(password, { list: true })
  if (!listOfInvalid) {
    return Promise.resolve()
  }

  const objectSchema: any = {
    uppercase: true,
    lowercase: true,
    digits: true,
    symbols: true,
    spaces: true,
  }
  listOfInvalid.forEach((errorType: string) => {
    objectSchema[errorType] = false
  })

  return objectSchema
}

export enum UserAdminType {
  GLOBAL_ADMIN = 'GlobalAdministrator',
  COMPANY_ADMIN = 'CompanyAdministrator',
  MEDICAL_ADMIN = 'MedicalAdministrator',
}

export const disabledDateUntilToday = (current: any) => {
  // Can not select days before today and today
  return current && current < moment().endOf('day')
}

export const disabledPreviousDate = (current: any) => {
  // Can not select days before today and today
  return current && current < moment().startOf('day')
}

export const compareValues = (key: string, order = 'asc') => {
  return function innerSort(a: any, b: any) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

    let comparison = 0
    if (varA > varB) {
      comparison = 1
    } else if (varA < varB) {
      comparison = -1
    }
    return order === 'desc' ? comparison * -1 : comparison
  }
}

export const eliminateDuplicateRecords = (records: any[], field: string) => {
  return records
    .map((t) => t[field])
    .reduce((a, b) => {
      if (a.indexOf(b) < 0) a.push(b)
      return a
    }, [])
}

export const SumFieldInRecords = (records: any[], key: string) => {
  return records.reduce((a, b) => a + (b[key] || 0), 0)
}

export const disabledDateTodayAfter = (current: any) => {
  // Can not select days before today and today
  return current && current > moment().startOf('day')
}

export const beautifyStringArrayDisplay = (
  strArray: string[],
  addAnd = true,
) => {
  if (addAnd) {
    const lastItem = strArray.pop()
    return strArray.length > 0
      ? `${strArray.join(', ')} and ${lastItem}`
      : lastItem
  } else return strArray.join(', ')
}

export const getMonths = () =>
  moment.months().map((v: any) => ({ label: v, value: v }))

export const getYears = (
  isDependant: boolean,
  forFutureDate = false,
  advanceYears = 0,
) => {
  let year = new Date().getFullYear()

  if (!isDependant && !forFutureDate) {
    year = year - 15
  }

  if (advanceYears) year += advanceYears

  return (forFutureDate
    ? Array.from(new Array(120), (_, i) => year + i)
    : Array.from(new Array(120), (_, i) => year - i)
  ).map((y) => ({ label: y, value: y }))
}

export const getFutureYears = () => {
  let year = new Date().getFullYear()
  return Array.from(new Array(50), (_, i) => year + i).map((y) => ({
    label: y,
    value: y,
  }))
}

export const getDays = (month: string, year: number) => {
  let days = 31
  if (month) {
    const date = moment().month(month)
    if (year) date.year(year)
    days = date.daysInMonth()
  }

  return Array.from(new Array(days), (_, i) => i + 1).map((d) => ({
    label: d,
    value: d,
  }))
}

export const sorter = (a: any, b: any) => {
  return isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b
}

export const sortArrayObject = (object: any, key: string, order = 'asc') => {
  return object.sort(compareValues(key, order))
}

export const onFinishFailed = (
  { errorFields }: any,
  form: FormInstance | null,
) => {
  if (form === null) return

  form.scrollToField(errorFields[0].name, {
    behavior: (actions) =>
      actions.map(({ el, top }) =>
        el.scrollTo({ top: top - 120, behavior: 'smooth' }),
      ),
  })
}

export const scrollToErrorField = (headingOffset = 90) => {
  // include header height
  const errorFields: any = document.querySelectorAll(
      '.ant-form-item-has-error',
    ),
    topOffset = errorFields[0].offsetTop + headingOffset
  scrollToTop(topOffset)
}

export const scrollToTop = (offsetTop: number) => {
  const container: any = window // document.getElementById('main-container');
  container.scrollTo({ top: offsetTop, behavior: 'smooth' })
}

export const formatMoney = (
  amount: any,
  decimalCount = 2,
  decimal = '.',
  thousands = ',',
) => {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? '-' : ''

    const i: any = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)),
    ).toString()
    const j = i.length > 3 ? i.length % 3 : 0

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    )
  } catch (e) {
    console.log(e)
  }
}

export const currencyParser = (val: any) => {
  const locale = 'en-us'
  try {
    // for when the input gets clears
    if (typeof val === 'string' && !val.length) {
      val = '0.0'
    }

    // detecting and parsing between comma and dot
    const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '')
    const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '')
    let reversedVal: any = val.replace(new RegExp('\\' + group, 'g'), '')
    reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.')
    //  => 1232.21 €

    // removing everything except the digits and dot
    reversedVal = reversedVal.replace(/[^0-9.]/g, '')
    //  => 1232.21

    // appending digits properly
    const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length
    const needsDigitsAppended = digitsAfterDecimalCount > 2

    if (needsDigitsAppended) {
      reversedVal = (
        parseFloat(reversedVal) * Math.pow(10, digitsAfterDecimalCount - 2)
      ).toString()
    }

    return Number.isNaN(reversedVal) ? 0 : reversedVal
  } catch (error) {
    console.error(error)
  }
}

export const currencyFormatter = (value: any) => {
  const locale = 'en-us'
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
  }).format(value) as any
}

export const generateGUID = (prefix?: string, length = 8) => {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let retVal: string = ''
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n))
  }
  return prefix + retVal
}

const limit = (val: any, max: any) => {
  if (val.length === 1 && val[0] > max[0]) {
    val = '0' + val
  }
  if (val.length === 2) {
    if (Number(val) === 0) {
      val = '00'
      //this can happen when user paste number
    } else if (val > max) {
      val = max
    }
  }
  return val
}

export const cardExpiry = (val: any) => {
  let month = limit(val.substring(0, 2), '12')
  let date = limit(val.substring(2, 4), '31')

  return month + (date.length ? '/' + date : '')
}

export const twentyFourHour = (val: any) => {
  let month = limit(val.substring(0, 2), '23')
  let date = limit(val.substring(2, 4), '23')

  return month + (date.length ? '/' + date : '')
}

export const twelveHour = (val: any) => {
  let month = limit(val.substring(0, 2), '12')
  let date = limit(val.substring(2, 4), '23')

  return month + (date.length ? '/' + date : '')
}

export const forceRoundUp = (val: number, precision = 2) => {
  const numArr = val.toString().split('.'),
    wholeValue = numArr[0],
    decimal = numArr[1]
  if (decimal && decimal.length > precision) {
    return Number(
      [wholeValue, Number(decimal.substr(0, precision)) + 1].join('.'),
    )
  }
  return val
}

export const getSumOfArrayObject = (Obj: any[], key: any) => {
  return Obj.reduce((a, b) => a + (b[key] || 0), 0)
}

export const titleCase = (str: any) => {
  str = str.toLowerCase().split(' ')
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
  }
  return str.join(' ')
}

export const getClientInitials = (profile: any) => {
  if (typeof profile === 'string') {
    const names = profile.split(' '),
      lastName = names.pop() || ''
    return [names[0][0], lastName[0]].join('').toUpperCase()
  } else
    return [profile.FirstName[0], profile.LastName[0]].join('').toUpperCase()
}

export const getStartDate = (
  appointmentDate?: moment.Moment,
  loaded = false,
) => {
  const today = moment()
  if (appointmentDate) {
    if (today.isSame(appointmentDate, 'day')) {
      return appointmentDate
    } else if (today.isAfter(appointmentDate, 'day')) {
      return appointmentDate.clone().startOf('month')
    } else if (today.isBefore(appointmentDate, 'day')) {
      if (today.isSame(appointmentDate, 'month')) {
        // Check if same month
        return today
      } else {
        return appointmentDate.clone().startOf('month')
      }
    }
  } else {
    return today
  }
}

export const getBirthDateCounts = (DOB: any) => {
  if (DOB) {
    let dates: any[] = [],
      today = moment(),
      days = today.diff(DOB, 'd')

    dates.push(
      moment.duration(days, 'days').format('y [years], M [months], d [days]'),
    )
    dates.push(moment.duration(days, 'days').format('M [months], d [days]'))
    dates.push(moment.duration(days, 'days').format('w [weeks], d [days]'))
    return dates
  } else return []
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const ordinal_suffix_of = (i: number) => {
  var j = i % 10,
    k = i % 100
  if (j === 1 && k !== 11) {
    return i + 'st'
  }
  if (j === 2 && k !== 12) {
    return i + 'nd'
  }
  if (j === 3 && k !== 13) {
    return i + 'rd'
  }
  return i + 'th'
}

export const cleanImageUrl = (url: string) => {
  const __a = url.split('/')
  const imageFname = __a.pop()
  return `${__a.join('/')}/${encodeURIComponent(imageFname || '')}`
}

export const shuffleSingleDimensionArray = (arra1: any[]) => {
  let ctr = arra1.length
  let temp
  let index

  // While there are elements in the array
  while (ctr > 0) {
    // Pick a random index
    index = Math.floor(Math.random() * ctr)
    // Decrease ctr by 1
    ctr--
    // And swap the last element with it
    temp = arra1[ctr]
    arra1[ctr] = arra1[index]
    arra1[index] = temp
  }
  return arra1
}
