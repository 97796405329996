import { 
	BillingAddressType,
	CheckoutType,
	ProductType, ProfileType, ShoppingCartType,
} from "./Types";

type ActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: M[Key] extends undefined
      ? {
          	type: Key;
        }
      : {
			type: Key;
			payload: M[Key];
        }
};

export enum Types {
    AddToCart = 'ADD_TO_CART',
	UpdateCartItem = 'UPDATE_CART_ITEM',
	ReplaceCartItems = 'REPLACE_CART_ITEMS',
	ToogleSelect = 'TOGGLE_SELECT_ITEM',
	BulkProductAdd = 'BULK_PRODUCT_ADD',
    Delete = 'DELETE_PRODUCT',
	CartUpdate = 'CART_UPDATE',
	SetSessionID = 'SET_SESSION_ID',
	RegisterToggledIds = 'REGISTER_TOGGLED_ID',

	// Checkout Details
	SetCheckoutInfo = "SET_CHECKOUT_INFO",

	// PROFILE TYPES
	SetUserProfile = 'SET_USER_PROFILE',

	SetUserDataIsLoaded = 'SET_USER_DATA_IS_LOADED',
}

type UserDataIsLoadedPayload = {
	[Types.SetUserDataIsLoaded]: boolean;
}

export type UserDataIsLoadedActions = ActionMap<UserDataIsLoadedPayload>[
	keyof ActionMap<UserDataIsLoadedPayload>
];

export const userDataIsLoadedReducer = (
	state: boolean, 
	action: UserDataIsLoadedActions | CheckoutInfoActions | ProfileActions | ProductActions | ShoppingCartActions
) => {
	switch (action.type) {
		case Types.SetUserDataIsLoaded:
			return action.payload;
		default:
			return state;
	}
}


type CheckoutInfoPayload = {
	[Types.SetCheckoutInfo]: CheckoutType;
}

export type CheckoutInfoActions = ActionMap<CheckoutInfoPayload>[
	keyof ActionMap<CheckoutInfoPayload>
];

export const checkoutInfoReducer = (
	state: CheckoutType, 
	action: UserDataIsLoadedActions | CheckoutInfoActions | ProfileActions | ProductActions | ShoppingCartActions
) => {
	switch (action.type) {
		case Types.SetCheckoutInfo:
			return action.payload;
		default:
			return state;
	}
}

// Profile & Billing Addresses
type ProfilePayload = {
	[Types.SetUserProfile]: ProfileType;
}

export type ProfileActions = ActionMap<ProfilePayload>[
	keyof ActionMap<ProfilePayload>
];

export const profileReducer = (
	state: ProfileType, 
	action: UserDataIsLoadedActions | CheckoutInfoActions | ProfileActions | ProductActions | ShoppingCartActions
) => {
	switch (action.type) {
		case Types.SetUserProfile:
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
}
// <-- END Profile & Billing Addresses


// Cart Products Items
type ProductPayload = {
    [Types.AddToCart] : ProductType;
	[Types.BulkProductAdd] : ProductType[];
	[Types.ReplaceCartItems] : ProductType[];
	[Types.UpdateCartItem]: {
		item_id: number;
        quantity?: number;
		isSelected?: boolean;
    };
	[Types.ToogleSelect]: boolean;
    [Types.Delete]: {
        item_id: number;
    }
}

export type ProductActions = ActionMap<ProductPayload>[
	keyof ActionMap<ProductPayload>
];

export const productReducer = (
	state: ProductType[], 
	action: UserDataIsLoadedActions | CheckoutInfoActions | ProfileActions | ProductActions | ShoppingCartActions
) => {
	switch (action.type) {
	  	case Types.AddToCart:
			return [
				...state,
				{ ...action.payload }
			]
		case Types.UpdateCartItem:
			return state.map(prod => {
				if (prod.item_id === action.payload.item_id) {
					if (action.payload.hasOwnProperty('isSelected')) {
						prod.isSelected = action.payload.isSelected;
					} else prod.isSelected = false
					return {
						...prod,
						quantity: action.payload.quantity || prod.quantity
					}
				}
				return prod
			});
	  	case Types.Delete:
			return [
				...state.filter(product => product.item_id !== action.payload.item_id),
			];
		case Types.BulkProductAdd:
			return [
				...state.concat(action.payload)
			];
		case Types.ReplaceCartItems:
			return action.payload;

		case Types.ToogleSelect:
			let currIds: any = [];
			if (!action.payload) {
				currIds = state.map(prod => prod.item_id);
			}
			localStorage.setItem('OMMSUnselectedCartItems', JSON.stringify(currIds));
			return state.map(prod => ({ ...prod, isSelected: action.payload }));

		default:
			return state;
	}
}
// <-- END Cart Products Items

  
// ShoppingCart
type ShoppingCartPayload = {
	[Types.CartUpdate]: ShoppingCartType;
	[Types.SetSessionID]: string;
	[Types.RegisterToggledIds]: {
		item_ids: number[]
	};
}
  
export type ShoppingCartActions = ActionMap<ShoppingCartPayload>[
	keyof ActionMap<ShoppingCartPayload>
];
  
export const shoppingCartReducer = (
	state: ShoppingCartType, 
	action: UserDataIsLoadedActions | CheckoutInfoActions | ProfileActions | ProductActions | ShoppingCartActions
) => {
	switch (action.type) {
		case Types.CartUpdate:
			return {
				...state,
				...action.payload
			};
		case Types.SetSessionID:
			return {
				...state,
				session_id: action.payload,
			}
		case Types.RegisterToggledIds:
			localStorage.setItem('OMMSUnselectedCartItems', JSON.stringify(action.payload.item_ids));
			return {
				...state,
				unselectedCartItems: action.payload.item_ids
			}
		default:
			return state;
	}
}
// <-- END ShoppingCart

