import axios from 'axios'

axios.interceptors.request.use((config) => {
  let authHeader: any = {}
  const token = localStorage.getItem('OMMSAuthToken')
  if (token) {
    authHeader.Authorization = `Bearer ${token}`
  }
  config.headers = {
    ...config.headers,
    ...authHeader,
    'content-type': 'application/json; charset=utf-8',
  }
  config.baseURL = process.env.REACT_APP_API_URL

  return config
})

axios.interceptors.response.use(
  (res) => {
    switch (res.status) {
      case 401: // Unauthorize
        break
    }
    return res
  },
  (errors) => {
    return Promise.reject(errors.response)
  },
)

export const getUserInfo = () => {
  return axios.get('/token/me').then((res) => res.data)
}

export const getCategoryByCode = (code: string) => {
  return axios.get(`/meta/info?code=${code}`).then((res) => res.data)
}

export function fetchAuthData(code: string) {
  let category = getCategoryByCode(code)
  return {
    category: wrapPromise(category),
  }
}

export const wrapPromise = (promise: Promise<any>) => {
  let status = 'pending'
  let result: any
  let suspender = promise.then(
    (res) => {
      status = 'success'
      result = res
    },
    (err) => {
      status = 'error'
      result = err
    },
  )

  return {
    read() {
      if (status === 'pending') {
        throw suspender
      } else if (status === 'error') {
        throw result
      }

      return result
    },
  }
}
