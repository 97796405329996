import React from 'react'
import { Form, FormInstance, Input } from 'antd'
import { Link, useHistory, useLocation } from 'react-router-dom'

import OmegaMedIco from '../Common/OmegaMedIco'

export default React.memo(() => {
  const location = useLocation()
  const history = useHistory()

  React.useEffect(() => {
    if (location.pathname.indexOf('search') < 0) {
      formRef.current?.resetFields()
    }
  }, [location.pathname])

  const formRef = React.createRef<FormInstance>()

  return (
    <Form
      ref={formRef}
      onFinish={(values: any) => {
        if (values.keyword) {
          history.push(`/search?keyword=${values.keyword}`)
        }
      }}
    >
      <div className="search-and-cart-wrap">
        <Form.Item name="keyword" noStyle>
          <Input
            placeholder="What medical products are you looking for?"
            type="search"
            suffix={
              <OmegaMedIco
                onClick={() => formRef.current?.submit()}
                asset="search"
                height="24"
                width="24"
                showPointer={true}
              />
            }
          />
        </Form.Item>
        <Link className="shopping-cart-link" to="/cart">
          <OmegaMedIco asset="shopping-cart" />
        </Link>
      </div>
    </Form>
  )
})
