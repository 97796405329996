import { message, Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
	Redirect,
	Route,
	Switch,
	useHistory,
	useLocation,
} from "react-router-dom";
import Footer from "./components/Layout/Footer";
import Header from "./components/Layout/Header";
import GlobalContext from "./context-api/GlobalContext";
import "./global.api";
import "./App.less";

// Public Pages
const AboutUsPage = React.lazy(() => import("./components/AboutUs"));
const BestSellers = React.lazy(() => import("./components/BestSellers"));
const BrandPage = React.lazy(() => import("./components/Brand"));
const BrandsPage = React.lazy(() => import("./components/Brands"));
const CategoriesPage = React.lazy(() => import("./components/Categories"));
const CategoryPage = React.lazy(() => import("./components/Category"));
const Checkout = React.lazy(() => import("./components/Checkout"));
const DealsAndPromos = React.lazy(() => import("./components/DealsAndPromos"));
const FAQsPage = React.lazy(() => import("./components/FAQs"));
const Products = React.lazy(() => import("./components/Products"));
const AllProducts = React.lazy(() => import("./components/AllProducts"));
const HomePage = React.lazy(() => import("./components/Home"));
const LoginPage = React.lazy(() => import("./components/Login"));
const ForgotPassword = React.lazy(() => import("./components/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./components/ResetPassword"));
const MyOrder = React.lazy(() => import("./components/MyOrder"));
const Profile = React.lazy(() => import("./components/Profile"));
const RegistrationPage = React.lazy(() => import("./components/Registration"));
const SearchResult = React.lazy(() => import("./components/SearchResult"));
const Invoice = React.lazy(() => import("./components/Invoice"));
// Private pages
const ShoppingCart = React.lazy(() => import("./components/ShoppingCart"));
const StoresPage = React.lazy(() => import("./components/Stores"));
// Temp pages
const ProductsByTag = React.lazy(() => import("./components/ProductsByTag"));

const App = () => {
	const context: any = useContext(GlobalContext);
	const history = useHistory();
	const location = useLocation();
	const [loaded, setLoaded] = useState(false);
	const currentPath = React.useRef(location.pathname);

	useEffect(() => {
		if (!loaded) {
			context.getCategories();
			setLoaded(true);
		}
	}, [context]);

	useEffect(() => {
		history.listen((location: any) => {
			const path = location.pathname + location.search; //.split("/")[1];
			if (currentPath !== path) {
				context.setPreviousPath(currentPath.current);
				currentPath.current = path;
				setTimeout(() => window.scrollTo({ top: 0 }), 10);
				message.destroy();
			}
		});
	}, []);

	return (
		<React.Fragment>
			<Header noShadow={context.noShadowHeader} />
			<main className="main-body" id={context.pageId}>
				{context.isAuthenticated === null ? (
					<Spin
						tip="Loading..."
						className="omms-page-spinner"
						size="large"
					/>
				) : (
					<SwitchRouters />
				)}
			</main>
			<Footer />
		</React.Fragment>
	);
};

const SwitchRouters = () => {
	return (
		<React.Suspense
			fallback={
				<div className="route-loader">
					<Spin size="large" tip="Loading page...Please wait" />
				</div>
			}
		>
			<Switch>
				<Route
					exact
					path="/"
					render={(props) => <HomePage {...props} />}
				/>
				<Route
					path="/login"
					render={(props) => <LoginPage {...props} />}
				/>
				<Route
					path="/forgot-password"
					render={(props) => <ForgotPassword {...props} />}
				/>
				<Route
					path="/resetPassword"
					render={(props) => <ResetPassword {...props} />}
				/>
				<Route
					path="/register"
					render={(props) => <RegistrationPage {...props} />}
				/>
				<Route
					path="/about-us"
					render={(props) => <AboutUsPage {...props} />}
				/>
				<Route
					path="/stores"
					render={(props) => <StoresPage {...props} />}
				/>
				<Route
					path="/faqs"
					render={(props) => <FAQsPage {...props} />}
				/>
				<Route
					path="/best-sellers"
					render={(props) => <BestSellers {...props} />}
				/>
				<Route
					exact
					path="/category"
					render={(props) => <CategoriesPage {...props} />}
				/>
				<Route
					exact
					path="/category/:categoryId"
					render={(props) => <CategoryPage {...props} />}
				/>
				{/* TEMPORARY */}
				<Route
					exact
					path="/products/tag/:code"
					render={(props) => <ProductsByTag {...props} />}
				/>
				<Route
					exact
					path="/brand"
					render={(props) => <BrandsPage {...props} />}
				/>
				<Route
					exact
					path="/brand/:brandId"
					render={(props) => <BrandPage {...props} />}
				/>
				<Route
					exact
					path="/products"
					render={(props) => <AllProducts {...props} />}
				/>
				<Route
					exact
					path="/products/:productId"
					render={(props) => <Products {...props} />}
				/>
				<Route
					path="/deals-and-promos"
					render={(props) => <DealsAndPromos {...props} />}
				/>
				<Route
					path="/search"
					render={(props) => <SearchResult {...props} />}
				/>
				<PrivateRoute path="/profile">
					<Profile />
				</PrivateRoute>
				<PrivateRoute path="/cart">
					<ShoppingCart />
				</PrivateRoute>
				<PrivateRoute path="/checkout">
					<Checkout />
				</PrivateRoute>
				<PrivateRoute path="/my-orders">
					<MyOrder />
				</PrivateRoute>
				<PrivateRoute path="/invoice/:invoiceId">
					<Invoice />
				</PrivateRoute>
			</Switch>
		</React.Suspense>
	);
};

const PrivateRoute = ({ children, ...rest }: any) => {
	const context: any = useContext(GlobalContext);
	return (
		<Route
			{...rest}
			render={({ location }) =>
				context.isAuthenticated ? (
					children
				) : (
					<Redirect
						to={{
							pathname: "/login", // Redirect to Login
							state: { from: location.pathname },
						}}
					/>
				)
			}
		/>
	);
};

export default App;
