import React, { useContext, useEffect, useState } from 'react'
import { Typography } from 'antd'
import { Link } from 'react-router-dom'

import OmegaMedIco from '../Common/OmegaMedIco'
import FooterCategoryLinks from './FooterCategoryLinks'

export default React.memo(() => {
  return (
    <footer className="main-footer">
      <FooterCategoryLinks />

      <div className="content-wrapper footer-links">
        <div>
          <Typography.Title level={5}>Customer Service</Typography.Title>
          <ul>
            <li>
              <Link to="/">
                <span>Help Center</span>
              </Link>
            </li>
            <li>
              <Link to="/">
                <span>Payment Methods</span>
              </Link>
            </li>
            <li>
              <Link to="/">
                <span>Return & Refund</span>
              </Link>
            </li>
            <li>
              <Link to="/">
                <span>Contact Us</span>
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <Typography.Title level={5}>Omega-Med</Typography.Title>
          <ul>
            <li>
              <Link to="/">
                <span>About Us</span>
              </Link>
            </li>
            <li>
              <Link to="/">
                <span>Testimonials</span>
              </Link>
            </li>
            <li>
              <Link to="/">
                <span>Deals</span>
              </Link>
            </li>
            <li>
              <Link to="/">
                <span>Rewards</span>
              </Link>
            </li>
          </ul>
        </div>
        {/* <div>
          <Typography.Title level={5}>Hotline</Typography.Title>
          <a className="hotline" href="telno: 0288543210">
            <OmegaMedIco height="28" width="28" asset="phone" />
            <span>(02) 88543210</span>
          </a>
        </div> */}
      </div>
      <div className="content-wrapper copy-right text-center">
        <span className="c-blue">
          {'\u00A9'} 2021 Omega-Med Supplies. All Rights Reserve
        </span>
      </div>
    </footer>
  )
})
