import axios from 'axios'
import moment from 'moment'
import React, {
  createContext,
  useReducer,
  Dispatch,
  useEffect,
  useContext,
} from 'react'
import {
  ProductType,
  ShoppingCartType,
  ProfileType,
  CheckoutType,
} from './Types'
import {
  CheckoutInfoActions,
  checkoutInfoReducer,
  productReducer,
  shoppingCartReducer,
  ProfileActions,
  ProductActions,
  ShoppingCartActions,
  Types,
  profileReducer,
  userDataIsLoadedReducer,
  UserDataIsLoadedActions,
} from './AppReducer'
import GlobalContext from '../context-api/GlobalContext'
import { fetchUserCartInfo } from './AppActions'

type InitialStateType = {
  userDataIsLoaded: boolean
  checkoutInfo: CheckoutType
  profile: ProfileType
  products: ProductType[]
  shoppingCart: ShoppingCartType
}

const initialState: InitialStateType = {
  userDataIsLoaded: false,
  checkoutInfo: {
    session_id: '',
    total: 0,
    sub_total: 0,
    number_of_items: 0,
    delivery_fee: 0,
    discount: 0,
    selectedBillAddress: undefined,
    items: [],
    products: [],
  },
  profile: {
    user_id: 0,
    first_name: '',
    last_name: '',
    email: '',
    mobile: '',
    birthday: moment(),
    gender: '',
    isStoreRep: false,
  },
  products: [],
  shoppingCart: {
    session_id: '',
    total: 0,
    sub_total: 0,
    number_of_items: 0,
    delivery_fee: 0,
    discount: 0,
  },
}

const AppContext = createContext<{
  state: InitialStateType
  dispatch: Dispatch<
    | UserDataIsLoadedActions
    | ProfileActions
    | ProductActions
    | ShoppingCartActions
    | CheckoutInfoActions
  >
}>({
  state: initialState,
  dispatch: () => null,
})

const mainReducer = (
  {
    userDataIsLoaded,
    profile,
    products,
    shoppingCart,
    checkoutInfo,
  }: InitialStateType,
  action:
    | UserDataIsLoadedActions
    | ProfileActions
    | ProductActions
    | ShoppingCartActions
    | CheckoutInfoActions,
) => ({
  userDataIsLoaded: userDataIsLoadedReducer(userDataIsLoaded, action),
  checkoutInfo: checkoutInfoReducer(checkoutInfo, action),
  profile: profileReducer(profile, action),
  products: productReducer(products, action),
  shoppingCart: shoppingCartReducer(shoppingCart, action),
})

const AppProvider: React.FC = ({ children }) => {
  const { sessionId, isAuthenticated, user }: any = useContext(GlobalContext)
  const [state, dispatch] = useReducer(mainReducer, initialState)

  useEffect(() => {
    if (isAuthenticated && user && sessionId) {
      const roles = user.roles.length
        ? user.roles.map((r: any) => r.name)
        : ['store_user']
      const profile = {
        ...user,
        mobile: user.user.mobile,
        roles,
        isStoreRep: roles.includes('store_rep'),
        birthday: moment(user.user.birthday || undefined),
        date_joined: moment(user.date_joined || undefined),
        last_login: moment(user.last_login || undefined),
      }

      dispatch({
        type: Types.SetUserProfile,
        payload: profile,
      })

      axios
        .get(`/address`)
        .then((res) => {
          const billing_addresses = res.data.data
          dispatch({
            type: Types.SetUserProfile,
            payload: {
              ...profile,
              billing_addresses,
            },
          })
        })
        .then(async () => {
          dispatch({
            type: Types.SetSessionID,
            payload: sessionId,
          })

          const cart = await fetchUserCartInfo()
          dispatch({
            type: Types.ReplaceCartItems,
            payload: cart?.products,
          })

          dispatch({
            type: Types.CartUpdate,
            payload: { ...cart?.info, loaded: true },
          })
        })
        .then(() => {
          dispatch({
            type: Types.SetUserDataIsLoaded,
            payload: true,
          })
        })
    } else if (!isAuthenticated) {
      dispatch({
        type: Types.SetUserDataIsLoaded,
        payload: true,
      })
    }
  }, [isAuthenticated, user, sessionId])

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <>{state.userDataIsLoaded ? children : null}</>
    </AppContext.Provider>
  )
}

export { AppProvider, AppContext }
